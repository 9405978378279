<template>
  <v-row align="center" justify="center">
      <v-col cols="12" sm="12" md="4"
        ><v-text-field
          outlined
          rounded
          clearable
          :dark="dark"
          :value="address1"
          @input="address1Fn($event)"
          label="Calle y numero de casa"
          placeholder="Calle y numero de casa"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="12" md="4"
        ><v-text-field
          outlined
          rounded
          clearable
          :dark="dark"
          :value="address2"
          @input="address2Fn($event)"
          label="Apartamento,local,etc."
          placeholder="Apartamento,local,etc."
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="12" md="4"
        >
        <v-select outlined
          rounded
          clearable
          :dark="dark" :items="provinces" :value="province"
          @change="provinceFn($event)"
          label="Provincia"
          placeholder="Provincia"></v-select>

      </v-col>
      <v-col cols="12" sm="12" md="4"
        ><v-select
          outlined
          rounded
          clearable
          :items="[{value:'Mexico',text:'México'}]"
          :dark="dark"
          :value="country"
          @change="countryFn($event)"
          label="País"
          placeholder="País"
        ></v-select>
      </v-col>
      <v-col cols="12" sm="12" md="4"
        ><v-text-field
          outlined
          rounded
          clearable
          :dark="dark"
          :value="zip"
          @input="zipFn($event)"
          label="Código postal"
          placeholder="Código postal"
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="12" md="4"
        ><v-text-field
          outlined
          rounded
          clearable
          :dark="dark"
          :value="city"
          @input="cityFn($event)"
          label="Ciudad"
          placeholder="Ciudad"
        ></v-text-field>
      </v-col>
  </v-row>
</template>

<script>
import { mapState} from 'vuex'
export default {
    computed:{
        ...mapState(['provinces'])
    },
    props:{
        address1:   String,
        address1Fn: Function,
        address2:   String,
        address2Fn: Function,
        province:   String,
        provinceFn: Function,
        country:    String,
        countryFn:  Function,
        zip:    String,
        zipFn:  Function,
        city:   String,
        cityFn: Function,
        dark: Boolean
    }
}
</script>

<style>

</style>